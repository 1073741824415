import { ViewNames, buildTextResourcesMH, viewNamespace } from "../../core/textResourceScopes";

export const AuctionDetailsTextResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "ExAuctionDetails"),
  resources: {
    title: "Auction",
    auctionDetailsTile: "AuctionDetails",
    listPageTitle: "Auction List",
    bundleTabTitle: "Bundle with {{count}} cars",
    auctionTypeLabel: "Auction Type",
    auctionStatusLabel: "Status",

    // Set Alternative Bid Dialog
    setAlternativeBidDialogTitle: "Set Alternative Bid",
    setAlternativeBidSuccessToast: "Alternative Bid successfully set",
    setAlternativeBidErrorToast: "Error setting alternative bid",
    setAlternativeBidPriceLabel: "Bid amount",
    setAlternativeBidderIdLabel: "Enter Bidder ID",
    setAlternativeBidDialogInfo: "Set an alternative bid for the auction.",
    setAlternativeBidButton: "Submit Bid",
    selectBidderLabel: "Select Bidder",

    // Account Picker Dialog
    accountPickerTitle: "Select an Account",
    accountPickerSearchPlaceholder: "Search by name or email",
    accountPickerNoResults: "No accounts found.",
    accountPickerError: "Error",

    // Buttons
    btnCancel: "Cancel",
    loading: "Loading..."
  }
});

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "CreateAuction"),
  resources: {
    title: "Create Auction",

    auctionInfoString: "{{maker}} {{model}} -  {{vin}}",
    auctionInfoSubTitle: "{{dealerName}}",

    createSingleAuctionTitle: "Create Auction",

    createSingleAuctionInfoText:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
    createBundleAuctionTitle: "Create Bundle Auction",
    createProspectAuctionTitle: "Create Prospect Auction",
    createDutchAuctionTitle: "Create Dutch",
    createSpotBuyAuctionTitle: "Create Spotbuy Offer",
    createAuctionButton: "Create Auction",
    selectAuctionTypeTitle: "Select Auction Type",

    auctionParameterTitle: "Auction parameters",

    selectCarsButton: "Select Cars",
    selectedCarsButton: "Show Selected",
    selectBundleCarsTitle: "Select Cars for Bundle ({{count}})",
    selectedBundleCarsTitle: "Cars in Bundle ({{count}})",
    removeCarButton: "Remove Car",
    addCarButton: "Add Cars to Bundle",

    referencePriceTitle: "Reference Price",
    missingDataTitle: "Missing Data",
    missingDataInfoTitle: "Why?",
    missingDataInfoText: "To guarantee the best price we suggest you provide the following Data.",
    titleAuctionsInWindow: "Auction Timing",
    subTitleTimeframe: "Auctions in Timeframe",
    noAuctionsInTimeframe: "No competing auctions found. It's a perfect time to schedule yours!",
    errorGettingAuctionsText: "Error getting Auctions in Timeframe",
    // referencePriceText: "Average Price: {{avgPrice}}",
    referencePriceText: "Average Price on MotorHammer",
    includedCriteriaTitle: "Included Criteria",
    //  criteriaExplanationText: "Included Criteria",
    calculateReferencePriceButton: "Calculate Reference Price",
    referencePriceBasedOnText: "Based on {{refAuctionsCount}} Auctions",
    criteriaExplanationText: "Price calculated based on the following criteria:",
    showReferenceAuctionsButton: "show Auctions",

    //  field labels
    startPriceInputLabel: "Start Price",
    spotBuyPriceInputLabel: "SpotBuy Price",
    reservePriceInputLabel: "Reserve Price",

    priceRangeLabel: "Price Range",
    priceRangeInfo: "Alle {{stepDuration}} Sek. wird der Preis um {{step}} € reduziert.",
    priceRangeWarning: "The Target Price must be at least 100€.",

    prospectDateLabel: "Prospect Date",

    durationSelectLabel: "Duration",
    durationMenuItemMinutesLabel: "{{min}} minutes",
    durationMenuItemHoursLabel: "{{std}} hours",

    // timeline

    ownAuctionItemLabel: "Your Auction",

    // reference Price
    averagePriceOnMHTitle: "Average Price on MotorHammer",
    basedOnXAuctionsLabel: "Based on {{count}} Auctions",
    noReferenceFoundLabel: "No References found",
    avaragePriceLabel: "Average Price: {{price}}",
    startPriceLabel: "Start Price: {{startPrice}}",
    highestBidLabel: "Highest Bid: {{highestBid}}",

    startAuction: "Start Auction",

    // Validations

    //  "maker",
    // "model",
    // "vintage",
    // "mileage",
    // "tuv",
    // "fuel",
    // "checkbook",
    // "tax",
    // "previousOwner",
    // "displacement",
    // "hp",
    // "kw",
    // "accident",
    // "images"
    missingField_maker: "Marke",
    missingField_model: "Model",
    missingField_vintage: "Vintage",
    missingField_milage: "",
    missingField_tuv: "",
    missingField_fuel: "",
    missingField_checkbook: "",
    missingField_tax: "",
    missingField_displacement: "",

    annotationDialogTitle: "Annotation",
    btnCancel: "Cancel",
    btnSave: "Save Annotation",
    byLabel: "by",
    annotationsTitle: "Seller's notes",

    renegotiationDialogTitle: "Renegotiation",
    renegotiationDialogInfo: "Enter a new price here, and we will do our best to match it for you.",
    renegotiationSendButton: "Send Renegotiation",
    renegotiationSuccessToast: "Renegotiation successfully send.",
    renegotiationNewPriceLabel: "New Price",
    // annotationsTitle: "Seller's notes",

    bidHistoryTitle: "Bid History",
    noBidsFoundLabel: "No bids found",
    expandBidHistoryTitle: "Expand to show bid history.",
    expandBidHistoryButton: "Expand",

    newNoteButton: "New Note",
    newNoteButtonInfo: "Add a note to the auction.",
    confirmationDialogTitle: "Delete Note",
    confirmationDialogMessage: "Do you really want to delete the note?",

    deal: "Deal",
    noDeal: "no Deal",
    open: "open",
    running: "running",

    datTileInfoTitle: "DAT Equipment",
    datTileSubTitle:
      "The equipment data displayed here for the vehicle is transmitted via the DAT interface. These data may contain inconsistencies or errors and do not always correspond to the actual vehicle equipment. Additionally, removable accessories such as tires, rims, storage compartments, etc., may no longer be included in the vehicle's delivery over time. Neither MotorHammer nor the seller assume any liability for discrepancies between the transmitted data and the actual equipment or for missing accessories."
  }
});

export const ExAuctionLisTextResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.auction, "ExAuctionList"),
  resources: {
    title: "Auction List",
    startPriceLabel: "Startprice",
    highestBidLabel: "Highest Bid",
    fuelType: "Mileage",
    initialRegistration: "Initial Registration",
    mileage: "Mileage",
    auctionEndsIn: "Auction ends",
    auctionEnded: "Auction has ended",
    auctionEnd: "Auction End",
    hammerTimeLabel: "It's HammerTime!",
    distanceTooltip: "aprox. {{distance}} as the crow flies",
    renegotiationRequested: "Renegotiation requested on {{date}} o'clock for {{price}}.",
    renegotiationAccepted: "Renegotiation accepted.",
    renegotiationRejected: "Renegotiation rejected."
  }
});
